import React, { useState } from 'react';

const MembersManager = () => {
  const [members, setMembers] = useState([
    {
      name: 'S. S. Rahman',
      email: 'ssrahman@lambdatest.com',
      role: 'Owner',
      dateJoined: '12/11/23, 5:40 PM'
    },
    {
        name: 'S. S. Rahman sir',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'S. S. Rahman ji ',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'S. S. Rahman',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'Mr. S. S. Rahman',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'S. S. Rahman',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'S. S. Rahman',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
      {
        name: 'S. S. Rahman',
        email: 'ssrahman@lambdatest.com',
        role: 'Owner',
        dateJoined: '12/11/23, 5:40 PM'
      },
    // ... other members
  ]);

  const [pendingInvitations, setPendingInvitations] = useState([
    {
      email: 'johndoe@example.com',
      role: 'Member',
      invitedAt: '12/24/23, 3:30 PM',
      status: 'Pending'
    }
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteForm, setInviteForm] = useState({
    role: 'Member',
    emails: ''
  });
  const [activeTab, setActiveTab] = useState('All members');

  const itemsPerPage = 5;

  // Filter based on search and active tab
  const filteredItems = activeTab === 'All members' 
    ? members.filter(member =>
        Object.values(member).some(value =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ))
    : pendingInvitations.filter(invitation =>
        Object.values(invitation).some(value =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ));

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handleInvite = (e) => {
    e.preventDefault();
    // Split emails by comma or space and create new pending invitations
    const newInvites = inviteForm.emails
      .split(/[\s,]+/)
      .filter(email => email.trim())
      .map(email => ({
        email: email.trim(),
        role: inviteForm.role,
        invitedAt: new Date().toLocaleString(),
        status: 'Pending'
      }));

    setPendingInvitations([...pendingInvitations, ...newInvites]);
    setInviteForm({ role: 'Member', emails: '' });
    setShowInviteModal(false);
  };

  const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg w-[500px]">
          {children}
        </div>
      </div>
    );
  };

  const handleResendInvite = (email) => {
    // Simulate resending invite
    alert(`Resending invite to ${email}`);
  };

  const handleCancelInvite = (email) => {
    setPendingInvitations(pendingInvitations.filter(invite => invite.email !== email));
  };

  // Add this new function for handling search
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-medium">Members</h1>
        <button 
          onClick={() => setShowInviteModal(true)}
          className="px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 flex items-center gap-2"
        >
          <span>+</span> Invite Members
        </button>
      </div>

      {/* Add the search bar here */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search members..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-2 border rounded-md"
        />
      </div>

      <div className="mb-6 border-b">
        <button
          className={`px-4 py-2 ${activeTab === 'All members' ? 'border-b-2 border-gray-900' : ''}`}
          onClick={() => {
            setActiveTab('All members');
            setCurrentPage(1);
          }}
        >
          All members <span className="ml-1 bg-gray-100 px-2 py-0.5 rounded-full text-sm">{members.length}</span>
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'Pending Invitations' ? 'border-b-2 border-gray-900' : ''}`}
          onClick={() => {
            setActiveTab('Pending Invitations');
            setCurrentPage(1);
          }}
        >
          Pending Invitations <span className="ml-1 bg-gray-100 px-2 py-0.5 rounded-full text-sm">{pendingInvitations.length}</span>
        </button>
      </div>

      <div className="overflow-x-auto border rounded-lg">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr className="border-b">
              {activeTab === 'All members' ? (
                <>
                  <th className="text-left p-4 font-medium text-sm">Name</th>
                  <th className="text-left p-4 font-medium text-sm">Email</th>
                  <th className="text-left p-4 font-medium text-sm">Role</th>
                  <th className="text-left p-4 font-medium text-sm">Date joined</th>
                  <th className="text-left p-4 font-medium text-sm">Actions</th>
                </>
              ) : (
                <>
                  <th className="text-left p-4 font-medium text-sm">Email</th>
                  <th className="text-left p-4 font-medium text-sm">Role</th>
                  <th className="text-left p-4 font-medium text-sm">Invited At</th>
                  <th className="text-left p-4 font-medium text-sm">Status</th>
                  <th className="text-left p-4 font-medium text-sm">Actions</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {activeTab === 'All members' ? (
              currentItems.map((member, index) => (
                <tr key={index} className="border-b">
                  <td className="p-4">{member.name}</td>
                  <td className="p-4">{member.email}</td>
                  <td className="p-4">
                    <select 
                      value={member.role}
                      className="border rounded-md p-1.5 pr-8"
                      onChange={(e) => {
                        const newMembers = [...members];
                        newMembers[index].role = e.target.value;
                        setMembers(newMembers);
                      }}
                    >
                      <option>Owner</option>
                      <option>Admin</option>
                      <option>Member</option>
                      <option>Guest</option>
                    </select>
                  </td>
                  <td className="p-4">{member.dateJoined}</td>
                  <td className="p-4">
                    <div className="flex gap-2">
                      <button className="text-gray-600 hover:text-gray-800 flex items-center gap-1">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                        </svg>
                        Reset password
                      </button>
                      <button className="text-red-600 hover:text-red-800 flex items-center gap-1">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              currentItems.map((invitation, index) => (
                <tr key={index} className="border-b">
                  <td className="p-4">{invitation.email}</td>
                  <td className="p-4">{invitation.role}</td>
                  <td className="p-4">{invitation.invitedAt}</td>
                  <td className="p-4">
                    <span className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-sm">
                      {invitation.status}
                    </span>
                  </td>
                  <td className="p-4">
                    <div className="flex gap-2">
                      <button 
                        onClick={() => handleResendInvite(invitation.email)}
                        className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        Resend
                      </button>
                      <button 
                        onClick={() => handleCancelInvite(invitation.email)}
                        className="text-red-600 hover:text-red-800 flex items-center gap-1"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center gap-2 mt-4">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-3 py-1">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}

      {/* Invite Modal */}
      <Modal show={showInviteModal} onClose={() => setShowInviteModal(false)}>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-medium">Invite Members</h2>
          <button onClick={() => setShowInviteModal(false)} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>
        <form onSubmit={handleInvite}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Select Role</label>
            <select
              value={inviteForm.role}
              onChange={(e) => setInviteForm({ ...inviteForm, role: e.target.value })}
              className="w-full p-2 border rounded-md"
            >
              <option>Member</option>
              <option>Admin</option>
              <option>Guest</option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">Enter Emails</label>
            <textarea
              value={inviteForm.emails}
              onChange={(e) => setInviteForm({ ...inviteForm, emails: e.target.value })}
              placeholder="Invite multiple users. You can paste multiple emails separated by comma or space"
              className="w-full p-2 border rounded-md h-32"
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setShowInviteModal(false)}
              className="px-4 py-2 border rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800"
            >
              Send Invite
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default MembersManager;
