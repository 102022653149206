import React from 'react'
import Navbar from './Navbar'
// import TableContacts from '../List/TableContacts'
// import ApiTable from './ApiTable'
import BillingHistoryTable from './BillingHistoryTable'

const BillingCredits = () => {
  return (
    <>
    <Navbar>
      <div>
        {/* <h1 className="text-2xl font-bold">Your Content Here BILLING</h1>
        <p>This will appear to the right of the navbar</p> */}
          
           
           {/* <TableContacts/> */}
           <BillingHistoryTable/>
      </div>
    </Navbar>
    </>
  )
}

export default BillingCredits






