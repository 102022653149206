import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Search } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import Cookies from 'js-cookie';

const transformApiFiltersToGroups = (apiFilters) => {
  return apiFilters.map(group => ({
    title: group.group_name,
    sections: group.filters.map(filter => ({
      id: filter.id,
      title: filter.title,
      isSearchable: filter.is_searchable || filter.title === 'Current Company' || filter.title === 'Past Company' || filter.title === 'Function',
      canBeExcluded: filter.can_be_excluded,
      onDemandValues: filter.on_demand_values || filter.title === 'Current Company' || filter.title === 'Past Company' || filter.title === 'Function'
    }))
  }));
};

const FilterItem = ({ item, sectionId, onInclude, onExclude, canBeExcluded }) => (
  <div className="flex justify-between items-center py-2 px-4">
    {canBeExcluded && (
      <span className="text-sm text-gray-900" style={{ 
        color: "#606060", 
        textAlign: "center", 
        fontFeatureSettings: "'liga' off, 'clig' off", 
        fontFamily: "Inter", 
        fontSize: "13px", 
        fontStyle: "normal", 
        fontWeight: 450, 
        lineHeight: "20px" /* 153.846% */ 
    }}>
        {item.name}
    </span>
     )}
    <div className="space-x-2">
      {canBeExcluded ? (
        <>
          <button 
  className="text-sm hover:text-green-800"
  style={{
    color: "#0C5132",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 450,
    lineHeight: "20px" // 153.846%
  }}
  onClick={() => onInclude(sectionId, item)}
>
  Include
</button>


          <button 
            className="text-sm  hover:text-red-800"
            style={{
              color: "#8E1F0B",
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 450,
              lineHeight: "20px" // 153.846%
            }}
            onClick={() => onExclude(sectionId, item)}
          >
            Exclude
          </button>
        </>
      ) : (
        <button
          className="text-sm text-gray-700 hover:text-gray-800"
          onClick={() => onInclude(sectionId, item)}
        >
          {item.name}
        </button>
      )}
    </div>
  </div>
);

const FilterTag = ({ item, type, onRemove }) => (
  <span 
  className={`px-2 py-1 rounded-md text-sm flex items-center gap-1 `}
  style={{ backgroundColor: type === 'include' ? '#CDFEE1' : '#FEE9E8',
    color:type === 'include' ? '#0C5132' : '#8E1F0B', }}
  
  >
    {item.name}
    <button 
  onClick={onRemove}
  className={`hover:${type === 'include' ? 'bg-green-100' : 'bg-red-100'} rounded-full ml-1`}
  style={{
    width: 'var(--size-500, 20px)',
    height: 'var(--size-500, 20px)',
    flexShrink: 0
  }}
>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M13.9697 15.0303C14.2626 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L11.0607 10L15.0303 6.03033C15.3232 5.73744 15.3232 5.26256 15.0303 4.96967C14.7374 4.67678 14.2626 4.67678 13.9697 4.96967L10 8.93934L6.03033 4.96967C5.73744 4.67678 5.26256 4.67678 4.96967 4.96967C4.67678 5.26256 4.67678 5.73744 4.96967 6.03033L8.93934 10L4.96967 13.9697C4.67678 14.2626 4.67678 14.7374 4.96967 15.0303C5.26256 15.3232 5.73744 15.3232 6.03033 15.0303L10 11.0607L13.9697 15.0303Z" fill="#4A4A4A"/>
  </svg>
</button>

  </span>
);

const SectionHeader = ({ title, expanded, onClick }) => (
  <button
    onClick={onClick}
    className="w-full flex justify-between items-center py-3 px-4 hover:bg-gray-50"
  >
    <span className="text-gray-600">{title}</span>
    <span className="text-gray-400">
  {expanded ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10C6 9.58579 6.33579 9.25 6.75 9.25H13.25C13.6642 9.25 14 9.58579 14 10C14 10.4142 13.6642 10.75 13.25 10.75H6.75C6.33579 10.75 6 10.4142 6 10Z" fill="#8A8A8A"/>
    </svg>  // Custom SVG for minus
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75L9.25 9.25H6.75C6.33579 9.25 6 9.58579 6 10C6 10.4142 6.33579 10.75 6.75 10.75H9.25L9.25 13.25C9.25 13.6642 9.58579 14 10 14C10.4142 14 10.75 13.6642 10.75 13.25V10.75H13.25C13.6642 10.75 14 10.4142 14 10C14 9.58579 13.6642 9.25 13.25 9.25H10.75V6.75Z" fill="#8A8A8A"/>
    </svg>  // Custom SVG for plus
  )}
</span>

  </button>
);

const tokenName = process.env.REACT_APP_PR_ACCESS_TOKEN || 'LocalAccessToken';
const token = Cookies.get(tokenName);

const CompanyFilters = () => {
  const location = useLocation();
  const isPeoplePage = location.pathname === '/search/contacts';
  const isCompaniesPage = location.pathname === '/search/companies';

  const [filterGroups, setFilterGroups] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [sectionResults, setSectionResults] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const fetchFilterValues = useCallback(async (sectionId, searchTerm = '') => {
    const filterConfig = activeFilters[sectionId];
    if (!filterConfig) return;

    // Only require search term for searchable sections
    if (filterConfig.isSearchable && !searchTerm) {
      setSectionResults(prev => ({ ...prev, [sectionId]: [] }));
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        filter_id: sectionId,
        start: '0',
        count: '10'
      });

      // Only add search term for searchable sections
      if (filterConfig.isSearchable && searchTerm) {
        queryParams.append('q', searchTerm);
      }
     
      const response = await fetch(
        `https://staging-api.lacleo.ai/api/v1/filters/search?${queryParams}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const data = await response.json();
      setSectionResults(prev => ({ ...prev, [sectionId]: data.results }));
    } catch (error) {
      console.error(`Error fetching results for ${sectionId}:`, error);
    }
  }, [activeFilters]);

  const debouncedFetchFilterValues = useMemo(
    () => debounce((sectionId, searchTerm) => {
      fetchFilterValues(sectionId, searchTerm);
    }, 500),
    [fetchFilterValues]
  );

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await fetch('https://staging-api.lacleo.ai/api/v1/filters', {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        const groups = transformApiFiltersToGroups(data.filters);
        setFilterGroups(groups);
        
        const initialStates = groups.reduce((acc, group) => {
          group.sections.forEach(section => {
            acc.expandedSections[section.id] = false;
            acc.searchTerms[section.id] = '';
            acc.selectedItems[section.id] = [];
            acc.activeFilters[section.id] = {
              onDemandValues: section.onDemandValues,
              isSearchable: section.isSearchable
            };
          });
          return acc;
        }, {
          expandedSections: {},
          searchTerms: {},
          selectedItems: {},
          activeFilters: {}
        });

        setExpandedSections(initialStates.expandedSections);
        setSearchTerms(initialStates.searchTerms);
        setSelectedItems(initialStates.selectedItems);
        setActiveFilters(initialStates.activeFilters);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  useEffect(() => {
    Object.entries(searchTerms).forEach(([sectionId, searchTerm]) => {
      if (expandedSections[sectionId]) {
        if (!activeFilters[sectionId]?.isSearchable) {
          // For non-searchable sections, fetch immediately when expanded
          fetchFilterValues(sectionId, '');
        } else if (searchTerm) {
          // For searchable sections, only fetch when there's a search term
          debouncedFetchFilterValues(sectionId, searchTerm);
        }
      }
    });
  }, [searchTerms, expandedSections, activeFilters, debouncedFetchFilterValues, fetchFilterValues]);

  const handleSectionToggle = useCallback((sectionId) => {
    setExpandedSections(prev => {
      const newState = { ...prev, [sectionId]: !prev[sectionId] };
      
      // If section is being expanded and it's not searchable, fetch results immediately
      if (newState[sectionId] && !activeFilters[sectionId]?.isSearchable) {
        fetchFilterValues(sectionId, '');
      }
      
      return newState;
    });
  }, [activeFilters, fetchFilterValues]);

  const handleInclude = useCallback((sectionId, item) => {
    setSelectedItems(prev => ({
      ...prev,
      [sectionId]: [...(prev[sectionId] || []), { ...item, type: 'include' }]
    }));
  }, []);

  const handleExclude = useCallback((sectionId, item) => {
    setSelectedItems(prev => ({
      ...prev,
      [sectionId]: [...(prev[sectionId] || []), { ...item, type: 'exclude' }]
    }));
  }, []);

  const handleRemove = useCallback((sectionId, itemId) => {
    setSelectedItems(prev => ({
      ...prev,
      [sectionId]: (prev[sectionId] || []).filter(item => item.id !== itemId)
    }));
  }, []);

  const renderFilterSection = useMemo(() => (section) => {
    const results = sectionResults[section.id] || [];
    const selected = selectedItems[section.id] || [];
    const searchTerm = searchTerms[section.id] || '';
    const isSearchable = activeFilters[section.id]?.isSearchable;

    return (
      <div key={section.id} className="border-b last:border-b-0">
        <SectionHeader
          title={section.title}
          expanded={expandedSections[section.id]}
          onClick={() => handleSectionToggle(section.id)}
        />

        {expandedSections[section.id] && (
          <div className="py-2">
            {selected.length > 0 && (
              <div className="flex flex-wrap gap-2 px-4 mb-2">
                {selected.map((item) => (
                  <FilterTag
                    key={`${item.type}-${item.id}`}
                    item={item}
                    type={item.type}
                    onRemove={() => handleRemove(section.id, item.id)}
                  />
                ))}
              </div>
            )}

            {isSearchable && (
              <div className="px-4">
                <div className="relative">
                  <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
                  <input
                    type="text"
                    placeholder={`Search ${section.title.toLowerCase()}`}
                    className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerms(prev => ({
                      ...prev,
                      [section.id]: e.target.value
                    }))}
                  />
                </div>
              </div>
            )}

            <div className="mt-2 max-h-64 overflow-y-auto">
              {(!isSearchable || (isSearchable && searchTerm)) && 
                results
                  .filter(item => !selected.some(s => s.id === item.id))
                  .map(item => (
                    <FilterItem 
                      key={item.id}
                      item={item}
                      sectionId={section.id}
                      onInclude={handleInclude}
                      onExclude={handleExclude}
                      canBeExcluded={section.canBeExcluded}
                    />
                  ))
              }
            </div>
          </div>
        )}
      </div>
    );
  }, [expandedSections, searchTerms, sectionResults, selectedItems, activeFilters, handleInclude, handleExclude, handleRemove, handleSectionToggle]);

  return (
    <div className="w-[360px] bg-white border rounded-lg shadow-sm overflow-auto">
      <header className="flex items-center px-4 py-1 bg-white border-b border-gray-200">
        <nav className="flex space-x-6">
          <Link
            to="/search/contacts"
            className={`flex items-center space-x-2 px-1 py-3 border-b-2 text-sm font-medium ${
              isPeoplePage 
                ? 'border-blue-600 text-blue-600' 
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.6001C9.68038 3.6001 7.79998 5.4805 7.79998 7.8001C7.79998 10.1197 9.68038 12.0001 12 12.0001C14.3196 12.0001 16.2 10.1197 16.2 7.8001C16.2 5.4805 14.3196 3.6001 12 3.6001ZM9.59998 7.8001C9.59998 6.47461 10.6745 5.4001 12 5.4001C13.3255 5.4001 14.4 6.47461 14.4 7.8001C14.4 9.12558 13.3255 10.2001 12 10.2001C10.6745 10.2001 9.59998 9.12558 9.59998 7.8001Z" 
        fill={isPeoplePage ? "#005BD3" : "#4A4A4A"} />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5807 17.0722C17.253 14.6823 14.7339 13.2001 12 13.2001C9.266 13.2001 6.74694 14.6823 5.41921 17.0722L4.89522 18.0154C4.30084 19.0853 5.07447 20.4001 6.29838 20.4001H17.7015C18.9255 20.4001 19.6991 19.0853 19.1047 18.0154L18.5807 17.0722ZM6.9927 17.9464C8.00296 16.1279 9.9197 15.0001 12 15.0001C14.0802 15.0001 15.997 16.1279 17.0072 17.9464L17.3704 18.6001H6.62953L6.9927 17.9464Z" 
        fill={isPeoplePage ? "#005BD3" : "#4A4A4A"} />
</svg>

<span 
  style={{
     // Ensure this CSS variable is defined in your styles
    textAlign: 'center',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '16px' // 100%
  }}
>
  People
</span>

          </Link>
          <Link
  to="/search/companies"
  className={`flex items-center space-x-2 px-1 py-3 border-b-2 text-sm font-medium ${
    isCompaniesPage 
      ? 'border-blue-600 text-blue-600' 
      : 'border-transparent text-gray-500 hover:text-gray-700'
  }`}
  style={{
    color: isCompaniesPage ? '#005BD3' : '#303030', // Conditional inline styles for color
    borderColor: isCompaniesPage ? '#005BD3' : 'transparent' // Apply border color conditionally
  }}
>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M8.1002 10.8C7.60314 10.8 7.2002 11.2029 7.2002 11.7C7.2002 12.197 7.60314 12.6 8.1002 12.6H8.7002C9.19725 12.6 9.6002 12.197 9.6002 11.7C9.6002 11.2029 9.19725 10.8 8.7002 10.8H8.1002Z" 
        fill={isCompaniesPage ? "#005BD3" : "#303030"} />
    <path d="M10.5002 11.7C10.5002 11.2029 10.9031 10.8 11.4002 10.8H12.0002C12.4973 10.8 12.9002 11.2029 12.9002 11.7C12.9002 12.197 12.4973 12.6 12.0002 12.6H11.4002C10.9031 12.6 10.5002 12.197 10.5002 11.7Z" 
        fill={isCompaniesPage ? "#005BD3" : "#303030"} />
    <path d="M11.4002 7.79995C10.9031 7.79995 10.5002 8.2029 10.5002 8.69995C10.5002 9.19701 10.9031 9.59995 11.4002 9.59995H12.0002C12.4973 9.59995 12.9002 9.19701 12.9002 8.69995C12.9002 8.2029 12.4973 7.79995 12.0002 7.79995H11.4002Z" 
        fill={isCompaniesPage ? "#005BD3" : "#303030"} />
    <path d="M7.2002 8.69995C7.2002 8.2029 7.60314 7.79995 8.1002 7.79995H8.7002C9.19725 7.79995 9.6002 8.2029 9.6002 8.69995C9.6002 9.19701 9.19725 9.59995 8.7002 9.59995H8.1002C7.60314 9.59995 7.2002 9.19701 7.2002 8.69995Z" 
        fill={isCompaniesPage ? "#005BD3" : "#303030"} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.2002 6.29995C4.2002 5.14015 5.1404 4.19995 6.3002 4.19995H13.8002C14.96 4.19995 15.9002 5.14015 15.9002 6.29995V6.89995H17.7002C18.86 6.89995 19.8002 7.84015 19.8002 8.99995V17.7C19.8002 18.8598 18.86 19.8 17.7002 19.8H6.3002C5.1404 19.8 4.2002 18.8597 4.2002 17.7V6.29995ZM9.3002 18H10.8002V16.5C10.8002 16.3343 10.6659 16.2 10.5002 16.2H9.6002C9.43451 16.2 9.3002 16.3343 9.3002 16.5V18ZM12.6002 18V16.5C12.6002 15.3402 11.66 14.4 10.5002 14.4H9.6002C8.4404 14.4 7.5002 15.3402 7.5002 16.5V18H6.3002C6.13451 18 6.0002 17.8656 6.0002 17.7V6.29995C6.0002 6.13427 6.13451 5.99995 6.3002 5.99995H13.8002C13.9659 5.99995 14.1002 6.13427 14.1002 6.29995V17.7C14.1002 17.8656 13.9659 18 13.8002 18H12.6002ZM15.9002 17.7V8.69995H17.7002C17.8659 8.69995 18.0002 8.83427 18.0002 8.99995V17.7C18.0002 17.8656 17.8659 18 17.7002 18H15.8789C15.8929 17.902 15.9002 17.8018 15.9002 17.7Z" 
        fill={isCompaniesPage ? "#005BD3" : "#303030"} />
</svg>

  <span 
  style={{
    // Ensure this CSS variable is defined in your styles
    textAlign: 'center',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '16px' // 100%
  }}
>
  Companies
</span>

</Link>

        </nav>
      </header>
      {/* <header className="w-full bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <nav className="flex flex-wrap items-center justify-start h-14 sm:h-16">
          <div className="flex space-x-2 sm:space-x-4 md:space-x-6">
            <Link
              to="/search/contacts"
              className={`flex items-center space-x-1 sm:space-x-2 px-1 py-2 sm:py-3 border-b-2 text-xs sm:text-sm md:text-base font-medium transition-colors duration-200 ${
                isPeoplePage 
                  ? 'border-blue-600 text-blue-600' 
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="w-5 h-5 sm:w-6 sm:h-6"
                viewBox="0 0 24 24" 
                fill="none"
              >
                <path 
                  fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 3.6001C9.68038 3.6001 7.79998 5.4805 7.79998 7.8001C7.79998 10.1197 9.68038 12.0001 12 12.0001C14.3196 12.0001 16.2 10.1197 16.2 7.8001C16.2 5.4805 14.3196 3.6001 12 3.6001ZM9.59998 7.8001C9.59998 6.47461 10.6745 5.4001 12 5.4001C13.3255 5.4001 14.4 6.47461 14.4 7.8001C14.4 9.12558 13.3255 10.2001 12 10.2001C10.6745 10.2001 9.59998 9.12558 9.59998 7.8001Z" 
                  fill={isPeoplePage ? "#005BD3" : "#4A4A4A"} 
                />
                <path 
                  fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M18.5807 17.0722C17.253 14.6823 14.7339 13.2001 12 13.2001C9.266 13.2001 6.74694 14.6823 5.41921 17.0722L4.89522 18.0154C4.30084 19.0853 5.07447 20.4001 6.29838 20.4001H17.7015C18.9255 20.4001 19.6991 19.0853 19.1047 18.0154L18.5807 17.0722ZM6.9927 17.9464C8.00296 16.1279 9.9197 15.0001 12 15.0001C14.0802 15.0001 15.997 16.1279 17.0072 17.9464L17.3704 18.6001H6.62953L6.9927 17.9464Z" 
                  fill={isPeoplePage ? "#005BD3" : "#4A4A4A"} 
                />
              </svg>
              <span className="font-medium">People</span>
            </Link>

            <Link
              to="/search/companies"
              className={`flex items-center space-x-1 sm:space-x-2 px-1 py-2 sm:py-3 border-b-2 text-xs sm:text-sm md:text-base font-medium transition-colors duration-200 ${
                isCompaniesPage 
                  ? 'border-blue-600 text-blue-600' 
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="w-5 h-5 sm:w-6 sm:h-6"
                viewBox="0 0 24 24" 
                fill="none"
              >
                <path 
                  d="M8.1002 10.8C7.60314 10.8 7.2002 11.2029 7.2002 11.7C7.2002 12.197 7.60314 12.6 8.1002 12.6H8.7002C9.19725 12.6 9.6002 12.197 9.6002 11.7C9.6002 11.2029 9.19725 10.8 8.7002 10.8H8.1002Z" 
                  fill={isCompaniesPage ? "#005BD3" : "#303030"} 
                />
                <path 
                  d="M10.5002 11.7C10.5002 11.2029 10.9031 10.8 11.4002 10.8H12.0002C12.4973 10.8 12.9002 11.2029 12.9002 11.7C12.9002 12.197 12.4973 12.6 12.0002 12.6H11.4002C10.9031 12.6 10.5002 12.197 10.5002 11.7Z" 
                  fill={isCompaniesPage ? "#005BD3" : "#303030"} 
                />
                <path 
                  d="M11.4002 7.79995C10.9031 7.79995 10.5002 8.2029 10.5002 8.69995C10.5002 9.19701 10.9031 9.59995 11.4002 9.59995H12.0002C12.4973 9.59995 12.9002 9.19701 12.9002 8.69995C12.9002 8.2029 12.4973 7.79995 12.0002 7.79995H11.4002Z" 
                  fill={isCompaniesPage ? "#005BD3" : "#303030"} 
                />
                <path 
                  d="M7.2002 8.69995C7.2002 8.2029 7.60314 7.79995 8.1002 7.79995H8.7002C9.19725 7.79995 9.6002 8.2029 9.6002 8.69995C9.6002 9.19701 9.19725 9.59995 8.7002 9.59995H8.1002C7.60314 9.59995 7.2002 9.19701 7.2002 8.69995Z" 
                  fill={isCompaniesPage ? "#005BD3" : "#303030"} 
                />
                <path 
                  fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M4.2002 6.29995C4.2002 5.14015 5.1404 4.19995 6.3002 4.19995H13.8002C14.96 4.19995 15.9002 5.14015 15.9002 6.29995V6.89995H17.7002C18.86 6.89995 19.8002 7.84015 19.8002 8.99995V17.7C19.8002 18.8598 18.86 19.8 17.7002 19.8H6.3002C5.1404 19.8 4.2002 18.8597 4.2002 17.7V6.29995ZM9.3002 18H10.8002V16.5C10.8002 16.3343 10.6659 16.2 10.5002 16.2H9.6002C9.43451 16.2 9.3002 16.3343 9.3002 16.5V18ZM12.6002 18V16.5C12.6002 15.3402 11.66 14.4 10.5002 14.4H9.6002C8.4404 14.4 7.5002 15.3402 7.5002 16.5V18H6.3002C6.13451 18 6.0002 17.8656 6.0002 17.7V6.29995C6.0002 6.13427 6.13451 5.99995 6.3002 5.99995H13.8002C13.9659 5.99995 14.1002 6.13427 14.1002 6.29995V17.7C14.1002 17.8656 13.9659 18 13.8002 18H12.6002ZM15.9002 17.7V8.69995H17.7002C17.8659 8.69995 18.0002 8.83427 18.0002 8.99995V17.7C18.0002 17.8656 17.8659 18 17.7002 18H15.8789C15.8929 17.902 15.9002 17.8018 15.9002 17.7Z" 
                  fill={isCompaniesPage ? "#005BD3" : "#303030"} 
                />
              </svg>
              <span className="font-medium">Companies</span>
            </Link>
          </div>
        </nav>
      </div>
    </header> */}

      <div className="pb-4">
        {filterGroups.map(group => (
          <div key={group.title}>
            <div 
  style={{
    display: 'flex',
    height: '50px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '4px',
    background: "#F3F3F3"  // Uses the CSS variable for background color
  }} 
  className="px-4 py-2"
>
            <h2 
  style={{
    color: "#606060",
    textAlign: "center",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 550,
    lineHeight: '20PX', // 142.857%
    marginTop: '12px',
    marginBottom : '12px'
  }}
>
  {group.title}
</h2>

            </div>
            {group.sections.map(renderFilterSection)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyFilters;



























