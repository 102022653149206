import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
// import Search from './Pages/Search';
// import Lists from './Pages/Lists';
import API from './Pages/API';
//import Settings from './Pages/Settings';
import AuthMiddleware from './middlewares/AuthMiddleware';
import { UserProvider } from './context/UserContext';
import Search2 from './Pages/Search/Search2';
import Companies from './Pages/Search/Companies';
import Contacts from './Pages/Search/Contacts';
import Setting from './Pages/Settings/Setting';
import BillingCredits from './Pages/Settings/BillingCredits';
import ApiKeys from './Pages/Settings/ApiKeys';
import Organization from './Pages/Settings/Organization';
import List from './Pages/List/List';
import ContactList from './Pages/List/ContactList';
import CompanyList from './Pages/List/CompanyList';

function App() {
  return (
    <UserProvider>
      <Router>
        <AuthMiddleware>
          <Layout>
            <Routes>
              <Route path="/" element={<Search2 />} />
              <Route path="/search/companies" element={<Companies />} />
              <Route path="/search/contacts" element={<Contacts />} />
              <Route path="/lists" element={<List />} />
              <Route path="/list/contacts" element={<ContactList />} />
              <Route path="/list/companies" element={<CompanyList />} />
              <Route path="/api" element={<API />} />
               <Route path="/settings" element={<Setting />} /> 
               <Route path="/settings/billing" element={<BillingCredits />} /> 
               <Route path="/settings/api-keys" element={<ApiKeys />} /> 
               <Route path="/settings/organization" element={<Organization />} /> 
            </Routes>
          </Layout>
        </AuthMiddleware>
      </Router>
    </UserProvider>
  );
}

export default App;
