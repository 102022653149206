import React, { useState } from 'react';

const BillingHistoryTable = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const initialData = [
    {
      invoice: '0B68DFBC-0009',
      credits: '1000 Credits',
      amount: '$100',
      status: 'Pending',
      created: '12/11/23, 5:40 PM',
    },
    {
      invoice: '0B68DFBC-0006',
      credits: '100,000 Credits',
      amount: '$1,200',
      status: 'Paid',
      created: '12/11/23, 5:40 PM',
    },
    {
      invoice: '0B68DFBC-0005',
      credits: '20,000 credits',
      amount: '$1,200',
      status: 'Failed',
      created: '12/1/24, 10:40 PM',
    },
    {
      invoice: '0B68DFBC-0009',
      credits: '1000 Credits',
      amount: '$100',
      status: 'Pending',
      created: '12/11/23, 5:40 PM',
    },
    {
      invoice: '0B68DFBC-0006',
      credits: '100,000 Credits',
      amount: '$1,200',
      status: 'Paid',
      created: '12/11/23, 5:40 PM',
    },
    {
      invoice: '0B68DFBC-0005',
      credits: '20,000 credits',
      amount: '$1,200',
      status: 'Failed',
      created: '12/1/24, 10:40 PM',
    },
    {
      invoice: '0B68DFBC-0006',
      credits: '100,000 Credits',
      amount: '$1,200',
      status: 'Paid',
      created: '12/11/23, 5:40 PM',
    },
    {
      invoice: '0B68DFBC-0005',
      credits: '20,000 credits',
      amount: '$1,200',
      status: 'Failed',
      created: '12/1/24, 10:40 PM',
    },
  ];

  // Filter data based on search query
  const filteredData = initialData.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  // Get status badge color
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'bg-orange-100 text-orange-800';
      case 'paid':
        return 'bg-green-100 text-green-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="w-full max-w-7xl h-auto mx-auto p-4"> {/* Adjusted max-width to '7xl' and height to 'auto' */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full max-w-lg px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" /* Increased max-width of the search input */
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Invoice
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Credits Purchased
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentData.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.invoice}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.credits}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.amount}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-2 py-1 rounded-full text-sm ${getStatusColor(row.status)}`}>
                    {row.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.created}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {row.status === 'Paid' && (
                    <button className="text-blue-600 hover:text-blue-800">
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center gap-2 mt-4">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-3 py-1">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default BillingHistoryTable;
