import React from 'react'
import Navbar from './Navbar'
import MembersManager from './MembersManager'
import SettingsPanel from './SettingsPanel'
const Organization = () => {
  return (
   <>
   <Navbar>
      <div>
        <SettingsPanel/>
        <MembersManager/>
       
      </div>
    </Navbar>
   
   </>
   
  )
}

export default Organization