import React, { useState } from 'react';
import { PenSquare, X } from 'lucide-react';

const SettingsPanel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState('LambdaTest');
  const [tempName, setTempName] = useState(organizationName);

  const handleSave = () => {
    setOrganizationName(tempName);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setTempName(organizationName);
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Settings Panel */}
      <div className="w-full">
        {/* Header Section */}
        <div className="flex items-center bg-#8A8A8A justify-between px-4 py-3 border-b border-gray-200 rounded-t-lg shadow-sm">
          <h2 className="text-base font-medium  text-gray-900">General settings</h2>
          <button 
            className="p-2 text-gray-400 hover:text-gray-500"
            onClick={() => {
              setTempName(organizationName);
              setIsModalOpen(true);
            }}
          >
            <PenSquare className="w-4 h-4" />
          </button>
        </div>

        {/* Content Section */}
        <div className="flex items-center gap-3 p-3 border-b border-gray-100 bg-white">
          <div className="flex items-center justify-center w-10 h-10 bg-gray-50 rounded">
            <svg
              className="w-5 h-5 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              />
            </svg>
          </div>

          <div className="flex-1">
            <div className="text-sm text-gray-500 mb-1">Organisation name</div>
            <div className="text-sm text-gray-900">{organizationName}</div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-md mx-4">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Edit Organization</h2>
              <button
                onClick={handleCancel}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={20} />
              </button>
            </div>

            <div className="p-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Organization Name
                </label>
                <input
                  type="text"
                  value={tempName}
                  onChange={(e) => setTempName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div className="flex justify-end gap-2">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsPanel;