import React from 'react';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';

import Navbar from './Navbar';
import MembersManager from './MembersManager'
import SettingsPanel from './SettingsPanel'


function Setting() {
  return (
    <AppProvider i18n={en}>
     <Navbar>
      <div>
        
        <SettingsPanel/>
        <MembersManager/>
       
      </div>
    </Navbar>
      
      
    </AppProvider>
  );
}

export default Setting;