import React from 'react';

import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import ListHead from './ListHead';
import TableContacts from './TableContacts';
const ContactList = () => {
  return (
    <AppProvider i18n={en}>
         

        <ListHead />
       
       <TableContacts />
      

    </AppProvider>
  );
};

export default ContactList;