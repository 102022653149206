import React from 'react'
import Navbar from './Navbar'
import ApiTable from './ApiTable'

const ApiKeys = () => {
  return (
    <>
  
    
    <Navbar>
      <div>
        
        <ApiTable/>
      </div>
    </Navbar>
    </>
  )
}

export default ApiKeys