import React from 'react';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';

import Contacts from './Contacts';



function Search2() {
  return (
    <AppProvider i18n={en}>
      {/* <SearchHead /> */}
      <Contacts />
    </AppProvider>
  );
}

export default Search2;
