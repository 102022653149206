import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const OrganizationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.75 9C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5H7.25C7.66421 10.5 8 10.1642 8 9.75C8 9.33579 7.66421 9 7.25 9H6.75Z" fill="#1A1A1A"/>
  <path d="M8.75 9.75C8.75 9.33579 9.08579 9 9.5 9H10C10.4142 9 10.75 9.33579 10.75 9.75C10.75 10.1642 10.4142 10.5 10 10.5H9.5C9.08579 10.5 8.75 10.1642 8.75 9.75Z" fill="#1A1A1A"/>
  <path d="M9.5 6.5C9.08579 6.5 8.75 6.83579 8.75 7.25C8.75 7.66421 9.08579 8 9.5 8H10C10.4142 8 10.75 7.66421 10.75 7.25C10.75 6.83579 10.4142 6.5 10 6.5H9.5Z" fill="#1A1A1A"/>
  <path d="M6 7.25C6 6.83579 6.33579 6.5 6.75 6.5H7.25C7.66421 6.5 8 6.83579 8 7.25C8 7.66421 7.66421 8 7.25 8H6.75C6.33579 8 6 7.66421 6 7.25Z" fill="#1A1A1A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H11.5C12.4665 3.5 13.25 4.2835 13.25 5.25V5.75H14.75C15.7165 5.75 16.5 6.5335 16.5 7.5V14.75C16.5 15.7165 15.7165 16.5 14.75 16.5H5.25C4.2835 16.5 3.5 15.7165 3.5 14.75V5.25ZM7.75 15H9V13.75C9 13.6119 8.88807 13.5 8.75 13.5H8C7.86193 13.5 7.75 13.6119 7.75 13.75V15ZM10.5 15V13.75C10.5 12.7835 9.7165 12 8.75 12H8C7.0335 12 6.25 12.7835 6.25 13.75V15H5.25C5.11193 15 5 14.8881 5 14.75V5.25C5 5.11193 5.11193 5 5.25 5H11.5C11.6381 5 11.75 5.11193 11.75 5.25V14.75C11.75 14.8881 11.6381 15 11.5 15H10.5ZM13.25 14.75V7.25H14.75C14.8881 7.25 15 7.36193 15 7.5V14.75C15 14.8881 14.8881 15 14.75 15H13.2323C13.244 14.9183 13.25 14.8349 13.25 14.75Z" fill="#1A1A1A"/>
</svg>
);

const ApiIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10.9507 11C11.21 10.5516 11.6948 10.25 12.25 10.25C13.0784 10.25 13.75 10.9216 13.75 11.75C13.75 12.5784 13.0784 13.25 12.25 13.25C11.6948 13.25 11.21 12.9484 10.9507 12.5H6.25C4.73122 12.5 3.5 11.2688 3.5 9.75V6.25C3.5 4.73122 4.73122 3.5 6.25 3.5H11.75C12.1642 3.5 12.5 3.83579 12.5 4.25C12.5 4.66421 12.1642 5 11.75 5H6.25C5.55964 5 5 5.55964 5 6.25V9.75C5 10.4404 5.55964 11 6.25 11H10.9507Z" fill="#4A4A4A"/>
  <path d="M14 16.25C15.5188 16.25 16.75 15.0188 16.75 13.5V10C16.75 8.48122 15.5188 7.25 14 7.25H9.29933C9.03997 6.80165 8.55521 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5C8.55521 9.5 9.03997 9.19835 9.29933 8.75H14C14.6904 8.75 15.25 9.30964 15.25 10V13.5C15.25 14.1904 14.6904 14.75 14 14.75H8.5C8.08579 14.75 7.75 15.0858 7.75 15.5C7.75 15.9142 8.08579 16.25 8.5 16.25H14Z" fill="#4A4A4A"/>
</svg>
);

const CreditCardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.25 11.25C5.83579 11.25 5.5 11.5858 5.5 12C5.5 12.4142 5.83579 12.75 6.25 12.75H9C9.41421 12.75 9.75 12.4142 9.75 12C9.75 11.5858 9.41421 11.25 9 11.25H6.25Z" fill="#4A4A4A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 7.25C2.5 5.73122 3.73122 4.5 5.25 4.5H14.75C16.2688 4.5 17.5 5.73122 17.5 7.25V12.75C17.5 14.2688 16.2688 15.5 14.75 15.5H5.25C3.73122 15.5 2.5 14.2688 2.5 12.75V7.25ZM14.75 6C15.4404 6 16 6.55964 16 7.25L4 7.25C4 6.55964 4.55964 6 5.25 6H14.75ZM16 9.25H4V12.75C4 13.4404 4.55964 14 5.25 14H14.75C15.4404 14 16 13.4404 16 12.75V9.25Z" fill="#4A4A4A"/>
</svg>
);

const NavItem = ({ icon: Icon, label, to }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className="mb-2">
      <Link
        to={to}
        className={`flex items-center p-2 rounded-lg ${
          isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-700 hover:bg-gray-50'
        }`}
      >
        <Icon className="w-5 h-5 mr-2" /> {/* Correct usage of Icon as a component */}
        <span>{label}</span>
      </Link>
    </li>
  );
};

const Navbar = ({ children }) => {
  const navItems = [
    {
      icon: OrganizationIcon, // Corrected, pass the component directly
      label: "Organization",
      to: "/settings/organization"
    },
    {
      icon: ApiIcon,
      label: "API Keys",
      to: "/settings/api-keys"
    },
    {
      icon: CreditCardIcon,
      label: "Billing and Credits",
      to: "/settings/billing"
    }
  ];

  return (
    <div className="flex min-h-screen">
      <nav className="bg-white w-64 shadow-lg">
        <div className="p-4">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">General settings</h3>
          <ul>
            {navItems.map((item) => (
              <NavItem key={item.to} icon={item.icon} label={item.label} to={item.to} />
            ))}
          </ul>
        </div>
      </nav>
      <main className="flex-1 p-8">
        {children}
      </main>
    </div>
  );
};

export default Navbar;
