import React, { useState, useCallback, useMemo } from 'react';
import { Search, ChevronLeft, Share2, MoreVertical, ArrowUpDown, ChevronRight } from 'lucide-react';

function TableContacts() {
  const [queryValue, setQueryValue] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [selectedList, setSelectedList] = useState(null);
  // const [selectedTab, setSelectedTab] = useState('All');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  // Mock contacts data (unchanged)
  const mockContacts = {
    '1001': [
      { id: 1, name: 'Alice Johnson', email: 'alice@example.com', phone: '(555) 123-4567', status: 'Active' },
      { id: 2, name: 'Bob Smith', email: 'bob@example.com', phone: '(555) 234-5674', status: 'Inactive' },
      { id: 3, name: 'Karan ', email: 'Karan@example.com', phone: '(555) 234-5675', status: 'Active' },
      { id: 4, name: 'Karan Pathak', email: 'Pathak@example.com', phone: '(555) 234-5676', status: 'Inactive' },
      { id: 5, name: 'Karan JIIT', email: 'JIIT@example.com', phone: '(555) 234-5677', status: 'Inactive' },
    ],
    '1002': [
      { id: 1, name: 'Alice Johnson', email: 'alice@example.com', phone: '(555) 123-4567', status: 'Active' },
      { id: 2, name: 'Bob Smith', email: 'bob@example.com', phone: '(555) 234-5674', status: 'Inactive' },
      { id: 3, name: 'Karan ', email: 'Karan@example.com', phone: '(555) 234-5675', status: 'Active' },
      { id: 4, name: 'Karan Pathak', email: 'Pathak@example.com', phone: '(555) 234-5676', status: 'Inactive' },
    ],
    '1003': [
      { id: 1, name: 'Alice Johnson', email: 'alice@example.com', phone: '(555) 123-4567', status: 'Active' },
      { id: 2, name: 'Bob Smith', email: 'bob@example.com', phone: '(555) 234-5674', status: 'Inactive' },
      { id: 3, name: 'Karan ', email: 'Karan@example.com', phone: '(555) 234-5675', status: 'Active' },
    ],
    '1004': [
      { id: 1, name: 'Alice Johnson', email: 'alice@example.com', phone: '(555) 123-4567', status: 'Active' },
      { id: 2, name: 'Bob Smith', email: 'bob@example.com', phone: '(555) 234-5674', status: 'Inactive' },
    ],
  };

  // Lists data (unchanged)
  const lists = useMemo(() => [
    {
      id: '1001',
      listName: 'Marketing Leads',
      numberOfContacts: 1500,
      createdBy: 'John Doe',
      createdOn: '2023-07-15',
      lastUpdatedOn: '2023-07-20',
    },
    {
      id: '1002',
      listName: 'Sales Prospects',
      numberOfContacts: 750,
      createdBy: 'Jane Smith',
      createdOn: '2023-07-10',
      lastUpdatedOn: '2023-07-18',
    },
    {
      id: '1003',
      listName: 'Newsletter Subscribers',
      numberOfContacts: 5000,
      createdBy: 'Mike Johnson',
      createdOn: '2023-06-30',
      lastUpdatedOn: '2023-07-19',
    },
    {
      id: '1004',
      listName: 'VIP Customers',
      numberOfContacts: 250,
      createdBy: 'Sarah Lee',
      createdOn: '2023-07-05',
      lastUpdatedOn: '2023-07-17',
    },
  ], []);

  const handleShare = useCallback(() => {
    alert('Share clicked');
  }, []);

  const sortData = useCallback((data, config = sortConfig) => {
    if (!config.key) return data;
    return [...data].sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'asc' ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const ContactsTable = ({ listId, onBack }) => {
    const [contactSortConfig, setContactSortConfig] = useState({ key: null, direction: 'asc' });
    const [searchQuery, setSearchQuery] = useState('');
    const [currentContactPage, setCurrentContactPage] = useState(1);

    const filteredAndSortedContacts = useMemo(() => {
      const contacts = mockContacts[listId] || [];
      let result = contacts;
      if (searchQuery) {
        const term = searchQuery.toLowerCase();
        result = result.filter(contact => 
          contact.name.toLowerCase().includes(term) ||
          contact.email.toLowerCase().includes(term) ||
          contact.phone.includes(term)
        );
      }
      return sortData(result, contactSortConfig);
    }, [searchQuery, contactSortConfig, listId]);

    // Pagination for contacts
    const totalContactPages = Math.ceil(filteredAndSortedContacts.length / itemsPerPage);
    const contactsStartIndex = (currentContactPage - 1) * itemsPerPage;
    const contactsEndIndex = contactsStartIndex + itemsPerPage;
    const currentContacts = filteredAndSortedContacts.slice(contactsStartIndex, contactsEndIndex);

    const requestContactSort = (key) => {
      let direction = 'asc';
      if (contactSortConfig.key === key && contactSortConfig.direction === 'asc') {
        direction = 'desc';
      }
      setContactSortConfig({ key, direction });
    };

    return (
      <div className="w-full">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 bg-white">
          <div className="flex items-center space-x-4">
            <button
              onClick={onBack}
              className="flex items-center text-gray-600 hover:text-gray-800"
            >
              <ChevronLeft className="h-5 w-5" />
              <span>Back</span>
            </button>
            {/* <h2 className="text-lg font-medium text-gray-900">{listName}</h2> */}
          </div>
          <div className="flex items-center space-x-4">
            <button onClick={handleShare} className="flex items-center text-gray-600 hover:text-gray-800">
              <Share2 className="h-5 w-5" />
              <span className="ml-2">Share</span>
            </button>
            <button className="text-gray-600 hover:text-gray-800">
              <MoreVertical className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Search */}
        <div className="p-4 bg-white">
          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <Search className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search contacts..."
              className="pl-10 pr-4 w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {/* Contacts Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50 border-y border-gray-200">
              <tr>
                <th className="w-4 p-4">
                  <input type="checkbox" className="rounded border-gray-300" />
                </th>
                {[
                  { key: 'name', label: 'Name' },
                  { key: 'email', label: 'Email' },
                  { key: 'phone', label: 'Phone' },
                  { key: 'status', label: 'Status' },
                ].map(({ key, label }) => (
                  <th
                    key={key}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    onClick={() => requestContactSort(key)}
                  >
                    <div className="flex items-center space-x-1">
                      <span>{label}</span>
                      <ArrowUpDown className="h-4 w-4" />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentContacts.map((contact) => (
                <tr key={contact.id} className="hover:bg-gray-50">
                  <td className="w-4 p-4">
                    <input type="checkbox" className="rounded border-gray-300" />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{contact.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{contact.email}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{contact.phone}</td>
                  <td className="px-6 py-4">
                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                      contact.status === 'Active' 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {contact.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          {/* Contacts Pagination */}
          <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200">
            <div className="flex items-center text-sm text-gray-500">
              Showing {contactsStartIndex + 1} to {Math.min(contactsEndIndex, filteredAndSortedContacts.length)} of {filteredAndSortedContacts.length} contacts
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentContactPage(prev => Math.max(prev - 1, 1))}
                disabled={currentContactPage === 1}
                className="px-2 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <span className="text-sm text-gray-700">
                Page {currentContactPage} of {totalContactPages}
              </span>
              <button
                onClick={() => setCurrentContactPage(prev => Math.min(prev + 1, totalContactPages))}
                disabled={currentContactPage === totalContactPages}
                className="px-2 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filteredAndSortedLists = useMemo(() => {
    let result = lists;
    if (queryValue) {
      const searchTerm = queryValue.toLowerCase();
      result = result.filter(list => {
        return (
          list.listName.toLowerCase().includes(searchTerm) ||
          list.createdBy.toLowerCase().includes(searchTerm) ||
          list.createdOn.includes(searchTerm) ||
          list.lastUpdatedOn.includes(searchTerm)
        );
      });
    }
    return sortData(result);
  }, [lists, queryValue, sortData]);

  // Pagination calculations for lists
  const totalPages = Math.ceil(filteredAndSortedLists.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLists = filteredAndSortedLists.slice(startIndex, endIndex);

  return (
    <div className="w-full pt-8 pl-8 pr-8">
      {selectedList ? (
        <ContactsTable 
          listId={selectedList} 
          onBack={() => setSelectedList(null)} 
        />
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full">
            {/* Table Header Section */}
            <thead>
              <tr className="border-b border-gray-500">
                <th colSpan="6" className="p-0">
                  <div className="flex items-center px-4 py-4 bg-gray-200 justify-between rounded-tl-lg rounded-tr-[10px]">
                    <button 
                      className="p-4 bg-gray-100 hover:bg-gray-200 rounded-lg h-[12px] flex items-center gap-2 ml-auto"
                      onClick={() => setIsSearchVisible(!isSearchVisible)}
                    >
                      <Search 
                        className={`h-5 w-5 ${isSearchVisible ? 'text-blue-500' : 'text-red-500'}`} 
                      />
                      <div className="flex flex-col gap-1">
                        <div className="w-4 h-0.5 bg-gray-600 rounded-full" />
                        <div className="w-3 h-0.5 bg-gray-600 rounded-full" />
                        <div className="w-2 h-0.5 bg-gray-600 rounded-full" />
                      </div>
                    </button>
                  </div>
                </th>
              </tr>
              {isSearchVisible && (
                <tr>
                  <th colSpan="6" className="p-0">
                    <div className="relative p-4">
                      {/* <div className="absolute inset-y-0 left */}
                       <div className="absolute inset-y-0 left-7 flex items-center">
                        <Search className="h-4 w-4 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        placeholder="Search lists"
                        className="pl-10 pr-4 py-2 border rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={queryValue}
                        onChange={(e) => setQueryValue(e.target.value)}
                      />
                    </div>
                  </th>
                </tr>
              )}
              {/* Regular Table Headers */}
              <tr className="bg-gray-50 border-y border-gray-300">
                <th className="w-4 p-4">
                  <input type="checkbox" className="rounded border-gray-500" />
                </th>
                {[
                  { key: 'listName', label: 'List Name' },
                  { key: 'numberOfContacts', label: 'Number of Contacts', align: 'right' },
                  { key: 'createdBy', label: 'Created By' },
                  { key: 'createdOn', label: 'Created On' },
                  { key: 'lastUpdatedOn', label: 'Last Updated On' },
                ].map(({ key, label, align }) => (
                  <th
                    key={key}
                    className={`px-6 py-3 text-xs font-medium text-gray-500 uppercase ${
                      align === 'right' ? 'text-right' : 'text-left'
                    }`}
                    onClick={() => requestSort(key)}
                  >
                    <div className={`flex items-center ${align === 'right' ? 'justify-end' : ''} space-x-1`}>
                      <span>{label}</span>
                      <ArrowUpDown className="h-4 w-4" />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentLists.map((list) => (
                <tr key={list.id} className="hover:bg-gray-50">
                  <td className="w-4 p-4">
                    <input type="checkbox" className="rounded border-gray-300" />
                  </td>
                  <td 
                    className="px-6 py-4 text-sm text-gray-900 cursor-pointer hover:text-blue-600"
                    onClick={() => setSelectedList(list.id)}
                  >
                    {list.listName}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 text-right">
                    {list.numberOfContacts.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{list.createdBy}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{list.createdOn}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{list.lastUpdatedOn}</td>
                </tr>
              ))}
            </tbody>
          </table>
          
          {/* Pagination */}
          <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200">
            <div className="flex items-center text-sm text-gray-500">
              Showing {startIndex + 1} to {Math.min(endIndex, filteredAndSortedLists.length)} of {filteredAndSortedLists.length} items
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-2 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronLeft className="h-4 w-4" />
              </button>
              <span className="text-sm text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-2 py-1 border rounded hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableContacts;















