import React from 'react';
import { Link, useLocation } from 'react-router-dom';


const ListHead = () => {
  const location = useLocation();
  const isCompaniesPage = location.pathname.includes('/list/companies');
  const isPeoplePage = location.pathname.includes('/list/contacts');

  return (
    <header className="flex items-center px-4 py-1  border-b border-gray-200">
      <nav className="flex space-x-6">
        <Link
          to="/list/contacts"
          className={`flex items-center space-x-2 px-1 py-3 border-b-2 text-sm font-medium ${
            isPeoplePage 
              ? 'border-blue-600 text-blue-600' 
              : 'border-transparent text-gray-500 hover:text-gray-700'
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5179 4H11.4821C12.2949 3.99999 12.9506 3.99999 13.4815 4.04336C14.0281 4.08803 14.5082 4.18239 14.9525 4.40873C15.6581 4.76825 16.2317 5.34193 16.5913 6.04754C16.8176 6.49176 16.912 6.9719 16.9566 7.51853C17 8.04944 17 8.70505 17 9.51788V10.4821C17 11.295 17 11.9506 16.9566 12.4815C16.912 13.0281 16.8176 13.5082 16.5913 13.9525C16.2317 14.6581 15.6581 15.2317 14.9525 15.5913C14.5082 15.8176 14.0281 15.912 13.4815 15.9566C12.9506 16 12.295 16 11.4821 16H8.51788C7.70505 16 7.04944 16 6.51853 15.9566C5.9719 15.912 5.49175 15.8176 5.04754 15.5913C4.34193 15.2317 3.76825 14.6581 3.40873 13.9525C3.18239 13.5082 3.08803 13.0281 3.04336 12.4815C2.99999 11.9506 2.99999 11.295 3 10.4821V9.5179C2.99999 8.70506 2.99999 8.04944 3.04336 7.51853C3.08803 6.9719 
            3.18239 6.49176 3.40873 6.04754C3.76825 5.34193 4.34193 4.76825 5.04754 4.40873C5.49175 4.18239 5.9719 4.08803 6.51853 4.04336C7.04943 3.99999 7.70507 3.99999 8.5179 4ZM5.875 9C5.875 7.8264 6.8264 6.875 8 6.875C9.1736 6.875 10.125 7.8264 10.125 9C10.125 10.1736 9.1736 11.125 8 11.125C6.8264 11.125 5.875 10.1736 5.875 9ZM4.75584 13.2842C4.77293 13.3195 4.79198 13.3566 4.81314 13.395C5.02999 13.7618 5.34714 14.0604 5.72852 14.2548C5.92604 14.3554 6.1868 14.4245 6.64068 14.4616C7.08399 14.4978 7.64814 14.4999 8.44454 14.5H12.4062C12.6978 14.5 12.8555 14.1687 12.6518 13.9601C12.3299 13.6303 11.9729 13.3346 11.5861 13.0787C10.7757 12.5427 9.85537 12.1951 8.89292 12.0616C7.93046 11.9281 6.95032 12.0121 6.02462 12.3074C5.64992 12.427 5.28801 12.5799 4.943 12.7637C4.75114 12.866 4.66116 13.0885 4.75584 
            13.2842ZM11.75 11C11.75 10.5858 12.0858 10.25 12.5 10.25H13.75C14.1642 10.25 14.5 10.5858 14.5 11C14.5 11.4142 14.1642 11.75 13.75 11.75H12.5C12.0858 11.75 11.75 11.4142 11.75 11ZM12.5 8C12.0858 8 11.75 8.33579 11.75 8.75C11.75 9.16421 12.0858 9.5 12.5 9.5H13.75C14.1642 9.5 14.5 9.16421 14.5 8.75C14.5 8.33579 14.1642 8 13.75 8H12.5Z" fill={isPeoplePage ? "#005BD3" : "#4A4A4A"}/>
          </svg>
          <span className="font-semibold">Contacts</span>
        </Link>

        <Link
          to="/list/companies"
          className={`flex items-center space-x-2 px-1 py-3 border-b-2 text-sm font-medium ${
            isCompaniesPage 
              ? 'border-blue-600 text-blue-600' 
              : 'border-transparent text-gray-500 hover:text-gray-700'
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M6.75 9C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5H7.25C7.66421 10.5 8 10.1642 8 9.75C8 9.33579 7.66421 9 7.25 9H6.75Z" fill={isCompaniesPage ? "#005BD3" : "#4A4A4A"}/>
            <path d="M8.75 9.75C8.75 9.33579 9.08579 9 9.5 9H10C10.4142 9 10.75 9.33579 10.75 9.75C10.75 10.1642 10.4142 10.5 10 10.5H9.5C9.08579 10.5 8.75 10.1642 8.75 9.75Z" fill={isCompaniesPage ? "#005BD3" : "#4A4A4A"}/>
            <path d="M9.5 6.5C9.08579 6.5 8.75 6.83579 8.75 7.25C8.75 7.66421 9.08579 8 9.5 8H10C10.4142 8 10.75 7.66421 10.75 7.25C10.75 6.83579 10.4142 6.5 10 6.5H9.5Z" fill={isCompaniesPage ? "#005BD3" : "#4A4A4A"}/>
            <path d="M6 7.25C6 6.83579 6.33579 6.5 6.75 6.5H7.25C7.66421 6.5 8 6.83579 8 7.25C8 7.66421 7.66421 8 7.25 8H6.75C6.33579 8 6 7.66421 6 7.25Z" fill={isCompaniesPage ? "#005BD3" : "#4A4A4A"}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H11.5C12.4665 3.5 13.25 4.2835 13.25 5.25V5.75H14.75C15.7165 5.75 16.5 6.5335 16.5 7.5V14.75C16.5 15.7165 15.7165 16.5 14.75 16.5H5.25C4.2835 16.5 3.5 15.7165 3.5 14.75V5.25ZM7.75 15H9V13.75C9 13.6119 8.88807 13.5 8.75 13.5H8C7.86193 13.5 7.75 13.6119 7.75 13.75V15ZM10.5 15V13.75C10.5 12.7835 9.7165 12 8.75 12H8C7.0335 12 6.25 12.7835 6.25 13.75V15H5.25C5.11193 15 5 14.8881 5 14.75V5.25C5 5.11193 5.11193 5 5.25 5H11.5C11.6381 5 11.75 5.11193 11.75 5.25V14.75C11.75 14.8881 11.6381 15 11.5 15H10.5ZM13.25 14.75V7.25H14.75C14.8881 7.25 15 7.36193 15 7.5V14.75C15 14.8881 14.8881 15 14.75 15H13.2323C13.244 14.9183 13.25 14.8349 13.25 14.75Z" fill={isCompaniesPage ? "#005BD3" : "#4A4A4A"}/>
          </svg>
          <span className="font-semibold">Companies</span>
        </Link>
      </nav>

      <div className="flex-grow" />
    </header>
  );
};

export default ListHead;