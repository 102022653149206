import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';


const ApiTable = () => {
  const [apiKeys, setApiKeys] = useState([
    {
      name: 'PrimeRole',
      key: '••••••••••••••••••••2ask31s',
      createdBy: 'ssrahman@lambdatest.com',
      createdAt: '12/11/23, 5:40 PM'
    },
    {
      name: 'Personal App',
      key: '••••••••••••••••••••la2j2nca',
      createdBy: 'surajk@lambdatest.com',
      createdAt: '12/11/23, 5:40 PM'
    },
    {
      name: 'Some App',
      key: '••••••••••••••••••••dwi2n2e',
      createdBy: 'amitp@lambdatest.com',
      createdAt: '12/11/24, 10:40 PM'
    }
  ]);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [newApiKey, setNewApiKey] = useState({
    name: '',
    createdBy: ''
  });

  const itemsPerPage = 4;

  // Filter based on search
  const filteredKeys = apiKeys.filter(key =>
    Object.values(key).some(value =>
      value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredKeys.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredKeys.length / itemsPerPage);

  const handleAddKey = (e) => {
    e.preventDefault();
    const newKey = {
      ...newApiKey,
      key: '••••••••••••••••••••' + Math.random().toString(36).substring(2, 8),
      createdAt: new Date().toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      })
    };
    setApiKeys([...apiKeys, newKey]);
    setNewApiKey({ name: '', createdBy: '' });
    setShowModal(false);
  };

  const handleRemove = (index) => {
    const newApiKeys = [...apiKeys];
    newApiKeys.splice(index, 1);
    setApiKeys(newApiKeys);
  };

  const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg w-96">
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">API Keys</h1>
        <button 
          onClick={() => setShowModal(true)}
          className="px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-800"
        >
          + New API Key
        </button>
      </div>

      <input
        type="text"
        placeholder="Search API keys..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      />

      <div className="overflow-x-auto border rounded-lg">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-left p-4 font-medium">Name</th>
              <th className="text-left p-4 font-medium">API Key</th>
              <th className="text-left p-4 font-medium">Created By</th>
              <th className="text-left p-4 font-medium">Created at</th>
              <th className="text-left p-4 font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index} className="border-t">
                <td className="p-4">{item.name}</td>
                <td className="p-4 font-mono">{item.key}</td>
                <td className="p-4">{item.createdBy}</td>
                <td className="p-4">{item.createdAt}</td>
                <td className="p-4">
                  <button
                    onClick={() => handleRemove(index)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-end gap-2 mt-4">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
        <ChevronLeft/>
        </button>
        {/* <span className="px-3 py-1">
          Page {currentPage} of {totalPages}
        </span> */}
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
        <ChevronRight/>
        </button>
      </div>

      {/* Modal */}
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <h2 className="text-xl font-bold mb-4">Create New API Key</h2>
        <form onSubmit={handleAddKey}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Name</label>
            <input
              type="text"
              value={newApiKey.name}
              onChange={(e) => setNewApiKey({ ...newApiKey, name: e.target.value })}

              placeholder="Enter API key name"
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Created By</label>
            <input
              type="email"
              value={newApiKey.createdBy}
              onChange={(e) => setNewApiKey({ ...newApiKey, createdBy: e.target.value })}
              placeholder="Enter email"
              className="w-full p-2 border rounded"
              required
            />
            
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="px-4 py-2 border rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-800"
            >
              Create API Key
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ApiTable;