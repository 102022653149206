import React from 'react';

import OrdersTable from './OrdersTable';
import CompanyFilters from './CompanyFilters';
import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';

const Companies = () => {
  return (
    <AppProvider i18n={en}>
      {/* <SearchHead /> */}
       <div style={{ display: 'flex',  alignItems: 'flex-start', gap: '2px' }}> 
        <CompanyFilters />
        <OrdersTable />
      </div>
    </AppProvider>
  );
};

export default Companies;
